.fa-android:before {
  content: '\e010';
}
.fa-angellist:before {
  content: '\e011';
}
.fa-apple:before {
  content: '\e012';
}
.fa-behance:before {
  content: '\e013';
}
.fa-bitbucket:before {
  content: '\e014';
}
.fa-bitcoin:before {
  content: '\e015';
}
.fa-bitcoin_alt:before {
  content: '\e016';
}
.fa-blacktie:before {
  content: '\e017';
}
.fa-blacktie_alt:before {
  content: '\e018';
}
.fa-buysellads:before {
  content: '\e019';
}
.fa-buysellads_alt:before {
  content: '\e01a';
}
.fa-cc_amex:before {
  content: '\e01b';
}
.fa-cc_discover:before {
  content: '\e01c';
}
.fa-cc_mastercard:before {
  content: '\e01d';
}
.fa-cc_paypal:before {
  content: '\e01e';
}
.fa-cc_stripe:before {
  content: '\e01f';
}
.fa-cc_visa:before {
  content: '\e020';
}
.fa-codepen:before {
  content: '\e021';
}
.fa-codiepie:before {
  content: '\e022';
}
.fa-connectdevelop:before {
  content: '\e023';
}
.fa-css:before {
  content: '\e024';
}
.fa-dashcube:before {
  content: '\e025';
}
.fa-delicious:before {
  content: '\e026';
}
.fa-deviantart:before {
  content: '\e027';
}
.fa-digg:before {
  content: '\e028';
}
.fa-dribbble:before {
  content: '\e029';
}
.fa-dropbox:before {
  content: '\e02a';
}
.fa-drupal:before {
  content: '\e02b';
}
.fa-empire:before {
  content: '\e02c';
}
.fa-facebook:before {
  content: '\e02d';
}
.fa-facebook_alt:before {
  content: '\e02e';
}
.fa-flickr:before {
  content: '\e02f';
}
.fa-flickr_alt:before {
  content: '\e030';
}
.fa-fonticons:before {
  content: '\e031';
}
.fa-fonticons_alt:before {
  content: '\e032';
}
.fa-forumbee:before {
  content: '\e033';
}
.fa-foursquare:before {
  content: '\e034';
}
.fa-git:before {
  content: '\e035';
}
.fa-github:before {
  content: '\e036';
}
.fa-github_alt:before {
  content: '\e037';
}
.fa-gittip:before {
  content: '\e038';
}
.fa-google:before {
  content: '\e039';
}
.fa-google_alt:before {
  content: '\e03a';
}
.fa-google_wallet:before {
  content: '\e03b';
}
.fa-googleplus:before {
  content: '\e03c';
}
.fa-googleplus_alt:before {
  content: '\e03d';
}
.fa-hackernews:before {
  content: '\e03e';
}
.fa-hackernews_alt:before {
  content: '\e03f';
}
.fa-html:before {
  content: '\e040';
}
.fa-instagram:before {
  content: '\e041';
}
.fa-ioxhost:before {
  content: '\e042';
}
.fa-joomla:before {
  content: '\e043';
}
.fa-jsfiddle:before {
  content: '\e044';
}
.fa-lastfm:before {
  content: '\e045';
}
.fa-leanpub:before {
  content: '\e046';
}
.fa-linkedin:before {
  content: '\e047';
}
.fa-linkedin_alt:before {
  content: '\e048';
}
.fa-linux:before {
  content: '\e049';
}
.fa-maxcdn:before {
  content: '\e04a';
}
.fa-meanpath:before {
  content: '\e04b';
}
.fa-meanpath_alt:before {
  content: '\e04c';
}
.fa-medium:before {
  content: '\e04d';
}
.fa-openid:before {
  content: '\e04e';
}
.fa-pagelines:before {
  content: '\e04f';
}
.fa-paypal:before {
  content: '\e050';
}
.fa-piedpiper:before {
  content: '\e051';
}
.fa-piedpiper_alt:before {
  content: '\e052';
}
.fa-pinterest:before {
  content: '\e053';
}
.fa-pinterest_alt:before {
  content: '\e054';
}
.fa-qq:before {
  content: '\e055';
}
.fa-queue:before {
  content: '\e056';
}
.fa-queue_alt:before {
  content: '\e057';
}
.fa-rebel:before {
  content: '\e058';
}
.fa-reddit:before {
  content: '\e059';
}
.fa-renren:before {
  content: '\e05a';
}
.fa-sellsy:before {
  content: '\e05b';
}
.fa-shirtsinbulk:before {
  content: '\e05c';
}
.fa-simplybuilt:before {
  content: '\e05d';
}
.fa-skyatlas:before {
  content: '\e05e';
}
.fa-skype:before {
  content: '\e05f';
}
.fa-slack:before {
  content: '\e060';
}
.fa-slideshare:before {
  content: '\e061';
}
.fa-soundcloud:before {
  content: '\e062';
}
.fa-spotify:before {
  content: '\e063';
}
.fa-stackexchange:before {
  content: '\e064';
}
.fa-stackoverflow:before {
  content: '\e065';
}
.fa-steam:before {
  content: '\e066';
}
.fa-steam_alt:before {
  content: '\e067';
}
.fa-stumbleupon:before {
  content: '\e068';
}
.fa-stumbleupon_alt:before {
  content: '\e069';
}
.fa-tencent_weibo:before {
  content: '\e06a';
}
.fa-trello:before {
  content: '\e06b';
}
.fa-tumblr:before {
  content: '\e06c';
}
.fa-tumblr_alt:before {
  content: '\e06d';
}
.fa-twitch:before {
  content: '\e06e';
}
.fa-twitter:before {
  content: '\e06f';
}
.fa-viacoin:before {
  content: '\e070';
}
.fa-vimeo:before {
  content: '\e071';
}
.fa-vimeo_alt:before {
  content: '\e072';
}
.fa-vine:before {
  content: '\e073';
}
.fa-vk:before {
  content: '\e074';
}
.fa-wechat:before {
  content: '\e075';
}
.fa-weibo:before {
  content: '\e076';
}
.fa-whatsapp:before {
  content: '\e077';
}
.fa-windows:before {
  content: '\e078';
}
.fa-wordpress:before {
  content: '\e079';
}
.fa-wordpress_alt:before {
  content: '\e07a';
}
.fa-xing:before {
  content: '\e07b';
}
.fa-yahoo:before {
  content: '\e07c';
}
.fa-yahoo_alt:before {
  content: '\e07d';
}
.fa-yelp:before {
  content: '\e07e';
}
.fa-youtube:before {
  content: '\e07f';
}
